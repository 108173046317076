<template>
  <div class="main-box">
    <div style="height:60px"></div> <!-- 占位 -->
    <!-- 面包屑 -->
    <div class="menu-inner">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/study/study">学习中心</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item style="color: #15b7dd">作业列表</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="homework-list">
      <h4 class="title">作业</h4>
      <!-- 作业列表 -->
      <div class="list-ul" v-if="list.length">
        <div class="item-li" v-for="(item,index) in list" :key="item.answerId">
          <!-- 状态icon -->
          <img class="icon-type" v-if="item.status == 1 || item.status == 0" src="./icon/icon-type1.png" alt="">  <!-- 待提交 -->
          <img class="icon-type" v-if="item.status == 3 || item.status == 7" src="./icon/icon-type2.png" alt="">  <!-- 待批阅 -->
          <img class="icon-type" v-if="item.status == 5" src="./icon/icon-type3.png" alt="">  <!-- 已驳回 -->
          <img class="icon-type" v-if="item.status == 8" src="./icon/icon-type4.png" alt="">  <!-- 已批阅 -->
          <!-- 作业详情 -->
          <div class="content-top">
            <div class="title-top">
              <div class="name-l">
                <p class="text1"><img src="./icon/icon6.png" alt="">{{item.homeworkTitle}}</p>
                <p class="text2"><img src="./icon/icon7.png" alt="">{{item.courseName}}</p>
              </div>
              <div class="time-r">
                <img src="./icon/icon12.png" alt="">
                截止上传时间：{{endTime(item.answerEnddate)}}
              </div>
            </div>
            <!-- 作业详情 -->
            <div class="info-bottom" 
              :ref="'hideText' + item.answerId" 
              :id="'hideText' + item.answerId"
              :class="{'info-bottom-hide' : answerHeightArry[index]}"
              v-if="item.status == 1 || item.status == 0">
              <div v-html="item.content"></div>
              <div class="develop" v-show="answerHeightArry[index]">
                <span @click="onShow(index)">展开<img src="./icon/icon10.png" alt=""></span>
              </div>
            </div>

            <!-- 作业概况 -->
            <div class="info-bottom" v-else>
              <div v-text="item.summary"></div>
            </div>
          </div>

          <!-- 驳回原因/教师评语 -->
          <div class="content-top" v-if="item.status == 8 || item.status == 5">
            <div class="title-top">
              <div class="name-l">
                <p class="text1"><img :src=" item.status == 5 ? errorIcon : successIcon" alt="">{{ item.status == 5 ? '驳回原因':'教师批阅' }}</p>
              </div>
            </div>
            <p class="info-score" v-show="item.status == 8">评分：{{item.score}}</p>
            <div class="info-bottom">
              <span v-if="item.status == 8">评语：</span>
              {{item.reply}}
            </div>
          </div>
          <div class="btn-bottom">
            <div style="max-width: 760px;" v-if="item.status == 1 || item.status == 0">
              <File-List :list="item.attachmentVOList || []" :isDown="1" />
            </div>
            <div v-else></div>
            <div class="btn-r">
              <p class="all-btn-small" :class="{'disable':(!(funDateSize(item.answerStartdate)) || funDateSize(item.answerEnddate))}" v-if="item.status == 1 || item.status == 0" @click="onSubmitPage(item)">作业提交</p>
              <p class="btn-white" v-if="item.status != 1 && item.status != 0" @click="onDetailPage(item.answerId, 1)">作业详情</p>
              <p :class="item.status == 5 ? 'btn-white' : 'all-btn-small'" v-if="item.status != 1 && item.status != 0" @click="onDetailPage(item.answerId, 2)">查看反馈</p>
              <p class="all-btn-small" :class="{'disable':(!(funDateSize(item.answerStartdate)) || funDateSize(item.answerEnddate))}" v-if="item.status == 5" @click="onSubmitPage(item)">重新提交</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 暂无课程 -->
      <a-empty
        v-else-if="!list.length && !loading"
        description="暂无作业"
        style="padding-top: 100px"
        :image="require('@/assets/image/stateHint/icon_work.png')"
        :image-style="{
          height: '180px',
        }"
      >
        <span class="nodata"></span>
      </a-empty>
      <a-spin class="sping" v-show="loading" />
    </div>
  </div>
</template>

<script>
import FileList from "./FileList.vue";
export default {
  // 可用组件的哈希表
  components: { FileList },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      answerHeightArry:[],

      errorIcon: require('./icon/icon8.png'),
      successIcon: require('./icon/icon9.png'),
      show:false,
      list:[],
      loading:false
    }
  },
  // 事件处理器
  methods: {
    // 处理时间
    endTime(e) {
      if(!e){
        return '';
      }
      let str = e.split('T').join(' ');
      str = str.substring(0,16);
      return str;
    },

    // 是否可以提交作业
    funDateSize(date1){
      var oDate1 = new Date(date1);
      var oDate2 = new Date();

      var isBegin = true
      if(oDate1.getTime() > oDate2.getTime()){
        isBegin = false
      } else {
        isBegin = true
      }
      return isBegin
    },
    
    
    // 展开详情
    onShow(index){
      this.$set(this.answerHeightArry, index, false)
    },

    // 提交作业
    onSubmitPage(e) {
      // 检测作答时间是否开始
      if(this.funDateSize(e.answerStartdate)){
        // 检测作答时间是否结束
        if(!this.funDateSize(e.answerEnddate)){
          this.$router.push({
            path: "/homework/homeworkSubmit?answerId=" + this.$AES.encode_data(e.answerId + ''),
          });
        } else {
          this.$message.error('作业作答时间已结束');
        }
      } else {
        this.$message.error('作业作答开始时间：' + e.answerStartdate);
      }
    },

    // 作业详情/反馈记录
    onDetailPage(id,type) {
      this.$router.push({
        path: "/homework/homeworkDetail?answerId=" + this.$AES.encode_data(id + '') + '&type=' + type,
      });
    },

    // 获取作业列表
    getHomeList(){
      this.loading = true
      this.$ajax({
        url: "/hxclass-pc/homework/student/list",
        params: {
          userId: this.$store.state.userInfo.userId
        },
      }).then((res) => {
        this.loading = false
        if(res.code == 200 && res.success) {
          this.list = res.data;
          let arry = res.data

          // 获取每个作业详情的div高度，超过限定高度显示展开按钮
          this.answerHeightArry = []
          this.$nextTick(()=>{
            arry.forEach((element,index) => {
              if(element.status == 1 || element.status == 0){
                const idName = 'hideText' + element.answerId
                const clientHeight = this.$refs[idName][0].clientHeight
                this.answerHeightArry.push((clientHeight > 100 ? true : false))
              } else {
                this.answerHeightArry.push(false)
              }
            });
          })
        }else {
          this.$message.error(res.message);
        }
      });
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getHomeList()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.menu-inner{
  /deep/ .ant-breadcrumb{
    line-height: 60px;
  }
}

.homework-list{
  background: #ffffff;
  padding: 30px;
  min-height: 500px;
  border-radius: 5px;
  position: relative;
  .sping{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 250px;
    margin-top: -10px;
    margin-left: -10px;
  }
  .title{
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #15B7DD;
    line-height: 23px;
    &::before{
      content: '';
      display: inline-block;
      width: 3px;
      height: 16px;
      background: #15B7DD;
      border-radius: 2px;
      margin-right: 4px;
      margin-bottom: -1px;
    }
  }

  .list-ul{
    margin-top: 18px;
    padding: 0 94px;
    .item-li{
      padding: 12px;
      background: #F6F6FC;
      border-radius: 10px;
      margin-bottom: 28px;
      overflow: hidden;
      position: relative;
      .icon-type{
        position: absolute;
        width: 90px;
        top: -3px;
        right: -3px;
      }
      .content-top{
        background: #ffffff;
        padding: 16px 20px;
        border-radius: 10px;
        margin-bottom: 8px;
        .title-top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 40px;
          .name-l{
            .text1{
              font-size: 18px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 27px;
              img{
                width: 14px;
                margin-right: 4px;
                margin-bottom: 4px;
              }
            }
            .text2{
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #C4C4C4;
              line-height: 18px;
              img{
                width: 12px;
                margin-right: 4px;
              }
            }
          }
          .time-r{
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 21px;
            img{
              width: 14px;
              margin-top: -2px;
            }
          }
        }
        .info-score{
          margin-top: 16px;
        }
        .info-bottom{
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          position: relative;
          /deep/strong{
            font-weight: bold;
          }
          /deep/em{
            font-style: italic;
          }
          /deep/img{
            max-width: 100%;
          }
          /deep/.remarks{
            max-width: 100%;
            height: auto !important;
            -webkit-line-clamp: 10000 !important;
          }
          /deep/ video{
            width: 100%;
          }
          .develop{
            position: absolute;
            bottom: -16px;
            text-align: center;
            width: 100%;
            height: 52px;
            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.55) 29%, #FFFFFF 100%);
            border-radius: 0px 0px 10px 10px;
            span{
              display: inline-block;
              color: @theme;
              margin-top: 20px;
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              line-height: 18px;
              cursor: pointer;
            }
            img{
              width: 8px;
            }
          }
        }
        .info-bottom-hide{
          height: 100px;
          overflow: hidden;
        }
      }
      .btn-bottom{
        margin-top: 17px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /deep/ .home-file{
          padding-left: 14px;
        }
        .btn-r{
          display: flex;
          .btn-white{
            width: 132px;
            height: 40px;
            background: #FFFFFF;
            border-radius:20px;
            text-align: center;
            line-height: 40px;
            color: @theme;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #15B7DD;
            cursor: pointer;
          }
          >p{
            margin-left: 28px;
          }

          .disable{
            background: #83d5e7;
          }
        }
      }
    }
  }
}
</style>
